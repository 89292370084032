<template>
    <view>
        <svg class="svg-icon" aria-hidden="true">
            <use :xlink:href="iconName"></use>
        </svg>
    </view>
</template>

<script lang="ts" setup>
const props = defineProps({
    name: {
        type: String,
        required: true,
    },
});

let iconName = computed(() => {
    return `#icon-${props.name}`;
});
</script>

<style scoped>
.svg-icon {
    width: 20px;
    height: 20px;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}
</style>
