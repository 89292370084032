
       if (typeof window !== 'undefined') {
         function loadSvg() {
           var body = document.body;
           var svgDom = document.getElementById('__svg__icons__dom__');
           if(!svgDom) {
             svgDom = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
             svgDom.style.position = 'absolute';
             svgDom.style.width = '0';
             svgDom.style.height = '0';
             svgDom.id = '__svg__icons__dom__';
             svgDom.setAttribute('xmlns','http://www.w3.org/2000/svg');
             svgDom.setAttribute('xmlns:link','http://www.w3.org/1999/xlink');
           }
           svgDom.innerHTML = "<symbol viewBox=\"0 0 1024 1024\" id=\"icon-house\"><path fill=\"currentColor\" d=\"M192 413.952V896h640V413.952L512 147.328 192 413.952zM139.52 374.4l352-293.312a32 32 0 0 1 40.96 0l352 293.312A32 32 0 0 1 896 398.976V928a32 32 0 0 1-32 32H160a32 32 0 0 1-32-32V398.976a32 32 0 0 1 11.52-24.576z\" /></symbol><symbol class=\"icon\" viewBox=\"0 0 1024 1024\"  id=\"icon-select_subject\"><path d=\"M165.171 159.949h817.767v81.92H165.17zM41.165 370.483h84.275v81.92H41.165zM165.17 576.614h408.883v81.92H165.171zm0 206.132H517.94v81.92H165.171zM41.165 576.614h84.275v81.92H41.165zm124.006-206.13h525.312v81.92H165.171zM41.165 782.745h84.275v81.92H41.165zm0-622.797h84.275v81.92H41.165zm965.632 539.34L642.56 577.742 764.211 942.08l100.967-101.274 80.998 80.794 40.346-40.346-80.999-80.998z\" fill=\"#07c160\" /></symbol><symbol class=\"icon\" viewBox=\"0 0 1024 1024\"  id=\"icon-subject\"><path d=\"M253.568 825.408A26.624 26.624 0 0 1 227.2 799.04c0-14.4 12.032-26.432 26.368-26.432H715.2c14.4 0 26.368 12.032 26.368 26.432a26.624 26.624 0 0 1-26.368 26.368H253.568zm0-165.568a26.624 26.624 0 0 1-26.368-26.432c0-14.4 12.032-26.368 26.368-26.368H715.2c14.4 0 26.368 11.968 26.368 26.368A26.624 26.624 0 0 1 715.2 659.84H253.568zm119.232-216 232.768-232.832a27.904 27.904 0 0 1 19.2-8c7.232 0 14.464 3.2 19.2 8a27.904 27.904 0 0 1 8.064 19.2c0 7.232-3.2 14.4-8.064 19.2L409.6 480.64l-74.368 37.568L372.8 443.84zm-111.232 569.6-3.968-.832A193.728 193.728 0 0 1 64 819.008V247.04a194.304 194.304 0 0 1 193.6-193.6h448a193.728 193.728 0 0 1 193.6 193.6v572.8a193.728 193.728 0 0 1-193.6 193.6H261.568zM257.6 105.408c-78.4 0-141.568 64-141.568 141.632v572.8c0 78.4 64 141.568 141.568 141.568h448c78.4 0 141.632-64 141.632-141.568v-572.8c0-78.4-64-141.632-141.632-141.632h-448z\" fill=\"#07c160\" fill-opacity=\".65\" /></symbol>";
           body.insertBefore(svgDom, body.lastChild);
         }
         if(document.readyState === 'loading') {
           document.addEventListener('DOMContentLoaded', loadSvg);
         } else {
           loadSvg()
         }
      }
        
export default {}